import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

function NotFoundPage() {
  return (
    <Layout>
      <SEO title='404: Not found' />
      <div className='flex flex-col items-center justify-center w-full min-h-screen'>
        <h1 className="bg-clip-text text-transparent bg-red-600 text-6xl font-bold">
          404
        </h1>
        <h2 className='text-2xl inline-block my-8 p-3 text-center max-w-3xl'>
          Sorry, but it looks like this page does not exist. Click below to
          return to the homepage.
        </h2>
        <Link
          to='/'
          className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'>
          Return Home
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
